import React from 'react'
import StyledIcon from '../StyledIcon'
import clsx from 'clsx'

import {
	labelWrapperRound,
	labelWrapper,
	labelText,
} from './StyledLabel.module.scss'

const labelColors = {
	articles: 'orange',
	videos: 'cyan',
	events: 'beige',
	podcasts: 'grey',
	'in-the-news': 'orange',
	'client-news': 'rust',
	'thought-leadership': 'rust',
	'case-studies': 'orange',
	filter: 'orange',
	'firm-news': 'rust',
	'press-releases': 'rust',
	webinar: 'purple',
	clock: 'light',
	time: 'light',
	left: 'grey',
}

const StyledLabel = ({
	iconColor,
	round,
	variant,
	name,
	label,
	size,
	...props
}) => {
	let labelClassnames = clsx(
		'label-small',
		round ? labelWrapperRound : labelWrapper
	)
	let displayText = label ?? variant

	return (
		<div className={labelClassnames}>
			<StyledIcon
				color={round && labelColors[variant]}
				iconColor={iconColor && labelColors[variant]}
				round={round}
				size={size}
				name={variant}
			/>
			<span className={labelText}>
				{variant === 'time' ? <span><span className="sr-only">Media Duration: </span>{props.time}</span> : displayText}
			</span>
		</div>
	)
}

export default StyledLabel
