import React from 'react'
import Form from 'react-bootstrap/Form'
import clsx from 'clsx'
import {
	searchField,
	searchInput,
	searchInputSmall,
	searchInputLarge,
	searchIcon,
	searchSubmit,
	searchIconSmall,
	searchIconMedium,
	searchIconLarge,
} from './SearchInput.module.scss'

const SearchInput = ({ inputName, placeholderText, searchLabel, ariaLabel, size, innerRef,iconSize, ...props }) => {
	const val = props.defaultValue ?? ''

	const searchInputClasses = clsx(
		'rounded-pill placeholder placeholder--italic',
		searchInput,
		size === 'lg' ? searchInputLarge : '',
		size === 'sm' ? searchInputSmall : ''
	)

	const searchIconClasses = clsx(
		'ss-black-tie-bold ss-icon',
		searchIcon,
		iconSize === 'sm' ? searchIconSmall : null,
		iconSize === 'md' ? searchIconMedium : null,
		iconSize === 'lg' ? searchIconLarge : null
	)

	return (
		<Form.Group className="mb-0">
			<div className={searchField}>
				<Form.Control
					defaultValue={val}
					name={inputName || 's'}
					className={searchInputClasses}
					type="text"
					aria-label={
						ariaLabel || 'Search by name, service, sector or location'
					}
					placeholder={
						placeholderText || 'Search by name, service, sector or location'
					}
					onChange={props.onInputChange}
					ref={innerRef}
				/>
				<button
					type="submit"
					onClick={props.onBtnClick}
					className={searchSubmit}
				>
					<span className="screen-reader-text">Submit Search Form</span>
					<i className={searchIconClasses}>search</i>
				</button>
			</div>

			{searchLabel && (
				<p className="d-none d-sm-block mb-0 pb-0 text-uppercase label-small text-right mt-1 mr-3">
					{searchLabel ? 'advanced search' : ''}
				</p>
			)}
		</Form.Group>
	)
}

export default SearchInput
